hr {
  background-color: #ff6a00;
}
h3 {
  font-size: 41px;
  font-family: 'Coolvetica';
  color: #ff6a00;
  font-weight: 400;
  margin-top: 30px;
  position: relative;
  margin-bottom: 40px;
  left: -10px;

  &:before {
    content: '<h3>';
    font-family: 'La Belle Aurore', cursive;
    color: #ff6a00;
    font-size: 18px;
    position: absolute;
    margin-top: -10px;
    left: 450px;
    opacity: 0.6;
    line-height: 18px;
  }

  &:after {
    content: '<h3/>';
    font-family: 'La Belle Aurore', cursive;
    color: #ff6a00;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    left: 450px;
    bottom: -20px;
    margin-left: 20px;
    opacity: 0.6;
  }
}
h4 {
  font-size: 34px;
  font-family: 'Coolvetica';
  color: #ff6a00;
  font-weight: 400;
  margin-top: 30px;
  position: relative;
  margin-bottom: 40px;
  left: 10px;
}

.about-page {
  display: flex;
  flex-direction: column;
  .top-zone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 600px;
    .text-zone {
      order: 1;
      width: 50%;
    }
    .stage-cube-cont {
      order: 2;
      width: 50%;
      height: 100%;
      top: 0;
      padding-top: 18%;
      margin-left: 70%;
      right: 0;
      overflow: hidden;
    }
  }
  .skills-zone {
    text-align: center;
    border: 2px solid #afafaf;
    padding: 0 25px;
    background: linear-gradient(90deg, #464646 0%, #afafaf 12%, #afafaf 88%, #464646 100%);
    margin: auto;
    width: 80%;
    order: 3;
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 30px;
      color: #ff6a00;
      img {
        height: 80px;
        margin: 0 15px;
        &:hover {
          animation: pulse 1s;
        }
      }
    }
  }
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.2);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }
  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {

  .about-page {
    min-height: 1000px;
    .top-zone {
      top: 0;
      display: flex;
      flex-direction: column;
      min-height: 500px;

      .text-zone {
        order: 1;
        width: 80%;
        margin-left: 0;
      }
      .stage-cube-cont {
        order: 2;
        width: 100%;
        margin-left: 0;
        margin-top: 300px;

      }
    }
    .skills-zone {
      width: 100%;
    }
  }
}