.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }
  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {
      content: '<h1>';
      font-family: "La Belle Aurore";
      color: #ff6a00;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 15px;
      opacity: 0.6;
    }

    &::after {
      content: '</h1>';
      font-family: "La Belle Aurore";
      color: #ff6a00;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      opacity: 0.6;
      animation: fadeIn 1s 1.7s backwards;
    }
    img {
      position: relative;
      top: 25px;
      left: 7px;
      width: 56px;
      opacity: 0;
      height: auto;
      animation: flash 1.5s linear both;
      animation-delay: 1.4s;
    }
  }
}
h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 400;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8 backwards;
}

.flat-button {
  color: #ff6a00;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ff6a00;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;
  &:hover {
    background: #ff6a00;
    color: #333;
  }
}
@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1:before,
  .home-page h1:after {
    display: none;
  }

  .home-page .text-zone {
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
  }

  .home-page .flat-button {
    float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }

  .logo-container {
    position: relative;
    width: 100px;
    height: auto;
    top: 50px;
    right: 0;
    box-sizing: border-box;
    margin: auto;
    left: 0;
  }

  .logo-container svg {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
  }
}