.photo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;


  .photo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    transform: rotateZ(10deg) !important;
    z-index: 1;
  }
}
