.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
}
.portfolio-page {
  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #ff6a00;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ff6a00;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ff6a00;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 45%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
  .resume {
    width: 40%;
    height: 50%;
    top: 0;
    padding-top: 10%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    display: flex;

    .resume-hover {
      color: #4d4d4e;
      background-color: #fff;
      border: #000 solid 1px;
      border-radius: 0 25px 25px 0;
      width: 53%;
      font-size: 36px;
      display: flex;
      align-items: center;
      padding: 0;
      &:hover {
        color: #ff6a00;
        border: #ff6a00 solid 3px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container {
    position: initial;
    height: auto;
    //min-height: auto;

    &.portfolio-page {
      .text-zone {
        transform: none;
        width: 80%;
        left: 15%;
        display: block;
        padding: 20px;
        box-sizing: border-box;
      }
    }
  }

  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
  }
}
